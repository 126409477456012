<mat-sidenav-container class="smart-component">
    <mat-sidenav mode="side" [opened]="sideNavOpened" class="smart-main-navigation alt-theme" *ngIf="currentUser">
        <div class="user-information-wrapper">
            <div class="logo-wrapper">
                <img class="user-avatar-icon" src="../../../assets/images/logo-white-shopliftr.svg" />
            </div>

            <div class="user-name text-bold">
                {{ currentUser.firstName }} {{ currentUser.lastName }}
            </div>

            <div>
                <button type="button" mat-button [matMenuTriggerFor]="profileActions">
                    <mat-icon>
                        settings
                    </mat-icon>
                </button>
            </div>

            <mat-menu #profileActions="matMenu">
                <button mat-menu-item (click)="logout()">
                    Logout
                </button>
            </mat-menu>
        </div>

        <hr />

        <mat-nav-list>
            <mat-list-item class="smart-nav-item" *ngIf="currentUser.hasPermission('campaign-read')" matTooltip="SMART Management" matTooltipPosition="after"
                routerLinkActive="current-route">
                <a matLine [routerLink]="['./designer/campaign']" [queryParams]="{}">
                    <mat-icon mat-list-icon>architecture</mat-icon>
                    <span>Designer</span>
                </a>
            </mat-list-item>
            <mat-list-item class="smart-nav-item" *ngIf="currentUser.hasPermission('campaign-read')" matTooltip="Reporting" matTooltipPosition="after"
                    routerLinkActive="current-route">
                <a matLine [routerLink]="['./reporting/campaign']" [queryParams]="{}">
                    <mat-icon mat-list-icon>grid_on</mat-icon>
                    <span>Reporting</span>
                </a>
            </mat-list-item>
            <mat-list-item class="smart-nav-item" matTooltip="Admin Application" matTooltipPosition="after"
                routerLinkActive="current-route">
                <a matLine [routerLink]="['./admin']" [queryParams]="{}" target="_blank" rel="noreferrer noopener">
                    <mat-icon mat-list-icon>queue_play_next</mat-icon>
                    <span>Admin</span>
                </a>
            </mat-list-item>
            <hr />
            <mat-list-item class="smart-nav-item" matTooltip="Report a Problem" matTooltipPosition="after">
                <a matLine [routerLink]="[]" (click)="onReportProblem()">
                    <mat-icon mat-list-icon>contact_support</mat-icon>
                    <span>Report a Problem</span>
                </a>
            </mat-list-item>
        </mat-nav-list>
        <div data-layout="row" data-layout-align="center end" class="version">
            <p>
                version: {{ build }}
            </p>
        </div>
    </mat-sidenav>
        <div class="main-content-wrapper" id="content">
            <div class="main-content-container">
                <router-outlet></router-outlet>
            </div>
        </div>
</mat-sidenav-container>